var App = {
    $body:$("body"),

    init: function init() {
        this.localStorage = localStorage.getItem('om-year-review');

        this.userNoAbonne = "";
        this.defaultNoAbonne = "1010895487";

        this.randomID =  Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

        this.$App = $("#App");
        this.$AppContent = $("#AppContent");
        this.$Parallax1 = $("#Parallax1");
        this.$Parallax2 = $("#Parallax2");
        this.parallaxBlocks = $(".parallax_block");

        this.$silhouetteDribble = $(".silhouette_dribble");
        this.$silhouetteDribbleJoueur = $(".silhouette_dribble_joueur");
        this.$silhouetteKeeper = $(".silhouette_keeper");

        this.$quizWrapper = $(".quiz_wrapper");
        this.$blockDiapo = $(".block_diapo");
        this.wHeight = $(window).height();
        this.wWidth = $(window).width();

        this.isMobile = true;
        if(this.wWidth < 700) this.isMobile = true;

        this.currentDiapoLeft = -85;
        window.odometerOptions = {
          auto: false
        }

        this.quizSlide = "intro";
        this.quizQuestionStep = 1;
        this.quizResults = {
            "question_1":"lose",
            "question_2":"lose",
            "question_3":"lose",
        }

        this.photoGoals = {
            "amiens":["amiens-balotelli", "amiens-thauvin"],
            "angers":["angers-balotelli1", "angers-balotelli2"],
            "caen":["caen-mitroglou", "caen-thauvin"],
            "dijon":["dijon-ocampos", "dijon-rami"],
            "guingamp":["guingamp-mitroglou","guingamp-payet","guingamp-thauvin1","guingamp-thauvin2"],
            "lille":["lille-balotelli"],
            "monaco":["monaco-lopez"],
            "nice":["nice-balotelli"],
            "rennes":["rennes-bensebaini", "rennes-ocampos"],
            "saint-etienne":["saint-etienne-balotelli", "saint-etienne-thauvin"],
            "strasbourg":["strasbourg-germain", "strasbourg-payet", "strasbourg-sanson"],
            "toulouse":["toulouse-germain", "toulouse-payet1", "toulouse-payet2", "toulouse-thauvin"]
        };

        this.userData = {
            games:["amiens", "nice", "toulouse"],
            photoGoals: []
        };

        $("#Splash .triangle_1").css("border-width", "0px 0px 80px "+this.wWidth+"px");
        $("#Splash .triangle_2").css("border-width", "0px 0px 100px "+this.wWidth+"px");

        this.isRendered = false;

        var Utils = require("utils");
        this.utils = new Utils();

        var Router = require("router");
        this.router = new Router();

        Backbone.history.start();
    },

    updateFromRouter:function(){
        var self = this;

        if(!this.isRendered) {
            $("#AppWrapper").addClass("displayed");
            this.isRendered = true;
            if(this.userNoAbonne == "") this.userNoAbonne = this.defaultNoAbonne;
            this.utils.gatherDBData(function(){
                self.utils.gatherCSVJoueurs(function(){
                    self.utils.gatherCSVGames(function(){
                        self.render();
                    });    
                });
            });
        }
    },

    render:function(){
        App.utils.saveUserProgression("intro");

        $(".content_block[data-block='intro']").addClass("animated");

        this.userTribune = App.utils.mapUserTribune[this.userData.aboData.secteur];
        console.log("userTribune", this.userTribune);
        if(this.userTribune == "De Peretti") this.userTribune = "Virage Nord";
        if(this.userTribune == "Chevalier Roze") this.userTribune = "Virage Sud";

        var maxGoalscorer = _.max(App.utils.joueurs, function(joueur){
            return joueur.buts;
        });
        this.userData.goleador = maxGoalscorer;

        var maxDribbler = _.max(App.utils.joueurs, function(joueur){
            return joueur.dribbles;
        });
        this.userData.dribbler = maxDribbler;

        var maxGK = _.max(App.utils.joueurs, function(joueur){
            return joueur.arrets;
        });
        this.userData.goalkeeper = maxGK;

        window.addEventListener('scroll', App.handleScroll);
        this.router.updateRoute();

        this.configureParallax();
        this.updateFigures();
        this.renderBlocks();
        this.renderDiaporama();

        if(this.localStorage !== "agreed") {
            $("body").addClass("locked_domed");
            setTimeout(function(){
                $("body").addClass("locked");
            }, 2000)
        }

    	this.bindEvents();
    },

    configureParallax: function () {
        setTimeout(function(){
            $(".parallax_block").addClass("displayed");
        }, 5);
        
        this.$Parallax1.css("top", $(".content_block[data-block='matchs']").offset().top + "px");
        this.$Parallax2.css("top", $(".content_block[data-block='matchs']").offset().top + "px");
    },

    updateFigures: function () {
        $(".content_block[data-block='matchs'] .hugefigure").attr("data-nbodometer", this.userData.games.length);
        if(this.userData.games.length <= 1) $(".content_block[data-block='matchs'] .waybigger_p").html("match");
        else $(".content_block[data-block='matchs'] .waybigger_p").html("matchs");

        $(".content_block[data-block='victoires'] .hugefigure").attr("data-nbodometer", this.userData.wins);
        if(this.userData.wins <= 1) $(".content_block[data-block='victoires'] .waybigger_p").html("victoire");
        else $(".content_block[data-block='victoires'] .waybigger_p").html("victoires");

        $(".content_block[data-block='buts'] .hugefigure").attr("data-nbodometer", this.userData.goals);
        if(this.userData.goals <= 1) $(".content_block[data-block='buts'] .waybigger_p").html("but");
        else $(".content_block[data-block='buts'] .waybigger_p").html("buts");

        $(".content_block[data-block='buts_diapo'] .nbgoals").html(this.userData.goals);
        if(this.userData.goals <= 1) {
            if(this.userData.goals == 0){
                $(".content_block[data-block='buts_diapo']").addClass("hasnodata");
                $(".goals_nodata").html(App.utils.maxUserData.goals);
            }
            $(".content_block[data-block='buts_diapo'] .buts").html("but");
            $(".content_block[data-block='buts_diapo'] .goleador_goals").html(" par ");
        } else {
            $(".content_block[data-block='buts_diapo'] .buts").html("buts");
            $(".content_block[data-block='buts_diapo'] .goleador_goals").html("dont "+ this.userData.goleador.buts + " inscrits par ");
        }
        $(".content_block[data-block='buts_diapo'] .nbgoals").html(this.userData.goals);
        var goleadorName = App.utils.joueursInfos[this.userData.goleador.id];
        if(goleadorName == undefined) goleadorName = "Mario Balotelli";
        $(".content_block[data-block='buts_diapo'] .goleador").html(goleadorName);

        $(".content_block[data-block='dribbles'] .hugefigure").attr("data-nbodometer", this.userData.dribbles);
        $(".content_block[data-block='dribbles'] .nbdribbles").html(this.userData.dribbles);
        if(this.userData.dribbles <= 1) $(".content_block[data-block='dribbles'] .waybigger_p").html("dribble");
        else $(".content_block[data-block='dribbles'] .waybigger_p").html("dribbles");

        $(".content_block[data-block='arrets'] .hugefigure").attr("data-nbodometer", this.userData.arrets);
        $(".content_block[data-block='arrets'] .nbdribbles").html(this.userData.arrets);
        $(".content_block[data-block='arrets'] .gk_arrets").html(this.userData.goalkeeper.arrets);
        var goalkeeperName = App.utils.joueursInfos[this.userData.goalkeeper.id];
        $(".content_block[data-block='arrets'] .gk_name").html(goalkeeperName);

        if(this.userData.arrets <= 1){
            if(this.userData.arrets == 0) {
                $(".content_block[data-block='arrets']").addClass("hasnodata");
                $(".arrets_nodata").html(App.utils.maxUserData.arrets);
            }
            $(".content_block[data-block='arrets'] .waybigger_p").html("arrêt");
        }
        else $(".content_block[data-block='arrets'] .waybigger_p").html("arrêts");

        $(".content_block[data-block='cartons'] .hugefigure").attr("data-nbodometer", this.userData.cartons_jaunes);

        if(this.userData.cartons_jaunes == 1){
            $(".cartons_text").html("carton jaune");
        } else if (this.userData.cartons_jaunes == 0) {
            $(".content_block[data-block='cartons']").addClass("hasnodata")
        } else{
            $(".cartons_text").html("cartons jaunes");
        }

        $(".content_block[data-block='cartons'] .cartons_nb").html(this.userData.cartons_jaunes);

        if(this.userData.cartons_rouges == 0) {
            $(".cartons_red").addClass("notdisplayed");
        } else {
            $(".content_block[data-block='cartons'] .rouges_nb").html(this.userData.cartons_rouges);    
        }

        if(App.userData.VAR == 0){
            $(".cartons_var").addClass("notdisplayed");
            $(".content_block[data-block='cartons'] .block_gif").addClass("notdisplayed");
            $(".content_block[data-block='cartons']").addClass("smaller");
        } else {
            if(App.userData.VAR > 1) $(".nbvar").html("Et à <b>" + App.userData.VAR + " reprises</b>");
            else $(".nbvar").html("Et une fois");
        }
        
        $(".content_block[data-block='statultime'] .bigfigure").attr("data-nbodometer", this.userData.minutes);
        $(".content_block[data-block='badge'] .hugefigure").attr("data-nbodometer", Math.round(this.userData.badge.badgePct));

        $(".content_block[data-block='supporters'] .supporters_nb").attr("data-nbodometer", App.userData.supporters);
        if(App.userData.games.length == 1) $(".nbmatchs_text").html("Lors du match où tu es venu");
        else $(".nbmatchs_text").html("Sur l’ensemble des " + App.userData.games.length + " matchs où tu es venu");

        $(".content_block[data-block='tribune'] .tribune_name").html(this.userTribune);

        $(".badge_label").html(App.userData.badge.id);
        $(".badge_attrtype").html(App.userData.badge.attrType);

        if(App.userData.aboData !== undefined && App.userData.aboData.secteur !== undefined){
            if(App.userTribune == "Virage Nord" || App.userTribune == "Virage Sud") {
                $(".user_tribune").html("le " + App.userTribune);
            } else {
                $(".user_tribune").html("la tribune " + App.userTribune);
            }
        } 

        $(".user_maxdribbler_nb").html(App.userData.dribbler.dribbles);
        $(".user_maxdribbler").html(App.utils.joueursInfos[App.userData.dribbler.id]);

        if(App.userData.cleansheets > 0) {
            $(".content_block[data-block='arrets'] .p2").addClass("displayed");
            $(".content_block[data-block='arrets'] .p2 .gk_cleansheets").html(App.userData.cleansheets)
        }

        $(".stadium_image").attr("src", "images/png-zones-stade/"+App.userData.zoneStade+".png");
        $(".user_ranking").html(_.str.numberFormat(App.userData.ranking, " ", " ", " "));
        $(".abonnes_total").html(_.str.numberFormat(App.utils.abonnesNb, " ", " ", " "));

        App.partageEncart = "Ma saison 18/19 à l’Orange\nVélodrome :\n\n⌛ "+this.userData.minutes+" minutes en "+this.userTribune+"\n⚡ "+App.userData.dribbles+" dribbles\n🚀 "+App.userData.tirs+" tirs\n⚽ "+App.userData.goals+" buts\n\nToutes mes stats sont ici :\n"+location.href
        $(".partage_encart").html(App.partageEncart);

        //var twitterMessage = App.partageEncart + " pic.twitter.com/r3LDXUFyih";
        $(".social_link[data-social='tw']").attr("href", "https://twitter.com/intent/tweet?text="+encodeURIComponent(App.partageEncart));
    },

    renderBlocks: function () {
        var self = this;

        //diaporama goals
        this.userData.photoGoals = [];
        _.each(this.userData.games, function(gameID){
            if(self.photoGoals[gameID] !== undefined) {
                self.userData.photoGoals.push(self.photoGoals[gameID]);
                self.userData.photoGoals = _.flatten(self.userData.photoGoals);
            }
        })

        if(this.userData.photoGoals.length == 0){
            this.userData.photoGoals = this.utils.maxUserData.photoGoals;
        }

        _.each(this.userData.photoGoals, function(pGoal){
            self.$blockDiapo.append("<div class='diapo'><div class='diapo_inner'><img src='images/buts/"+pGoal+".jpg' /></div></div>");
        })

        //quiz block height
        //$(".content_block[data-block='quiz']").css("height", (this.wHeight*(95/100)) + "px");

        //quiz answers
        _.each(App.utils.quizJoueurs, function(questionData, questionID){
            var $quizSlide = $(".quiz_slide[data-slide='"+questionID+"']");
            $quizSlide.attr("data-goodanwser", questionData.goodAnswer);

            $quizSlide.find(".toggle_player .answer_playerpic").attr("data-player", questionData.goodAnswer);
            $quizSlide.find(".toggle_player .answer_playername").html(App.utils.joueursInfos[questionData.goodAnswer]);
            $quizSlide.find(".toggle_player .player_name").html(App.utils.joueursInfos[questionData.goodAnswer]);
            $quizSlide.find(".toggle_player .player_nb").html(questionData.goodAnswerNb);

            _.each(questionData.joueurs, function(joueurData, inc) {
                var divInc = inc + 1;
                var $answerBt = $quizSlide.find(".answer_bt[data-answer='"+divInc+"']");
                $answerBt.attr("data-joueur", joueurData.id);
                $answerBt.find(".answer_playerpic").attr("data-player", joueurData.id);
                $answerBt.find(".answer_playername").html(App.utils.joueursInfos[joueurData.id]);
            });
        })

        this.renderQ3Top5();

        //user badge
        $(".badge_picto").attr("data-picto", App.userData.badge.id)

        //badge teams played
        _.each(this.userData.games, function(gameID){
            $(".logo[data-team='"+gameID+"']").addClass("active");
        })

        //dribbler joueur silhouette
        this.$silhouetteDribbleJoueur.find("img").attr("src", "images/dribble/"+App.userData.dribbler.id+"_contour.png");
        this.$silhouetteDribbleJoueur.attr("data-joueur", App.userData.dribbler.id);

        if(this.userData.arrets == 0) {
            this.$silhouetteKeeper.find("img").attr("src", "images/gardien/mandanda_contour.png");
            this.$silhouetteKeeper.attr("data-joueur", "mandanda");
        } else {
            this.$silhouetteKeeper.find("img").attr("src", "images/gardien/"+App.userData.goalkeeper.id+"_contour.png");
            this.$silhouetteKeeper.attr("data-joueur", App.userData.goalkeeper.id);
        }

        $(".picto_badge .picto_img").css("background-image", "url('images/badges/anim-badge-icone_"+App.userData.badge.id+".svg')")
        $(".badge_text").html(App.userData.badge.badgeText);

        //specific huis clos
        if(App.userData.groupeSupp == "Fanatics") {
            $(".logo[data-team='lille']").addClass("notdisplayed");
            $(".logo[data-team='amiens']").addClass("notdisplayed");
        } else if(App.userData.groupeSupp == "Commando Ultras 84") {
            $(".logo[data-team='dijon']").addClass("notdisplayed");
            $(".logo[data-team='reims']").addClass("notdisplayed");
        }
    },

    renderQ3Top5: function () {
        $(".q3_top5").empty()
        _.each(App.utils.quizJoueurs["question_3"].top5, function(player, index){
            console.log("renderQ3Top5", player);
            $(".q3_top5").append("<div class='top5_player'><div class='player_diamond'>"+(index+2)+"</div><div class='player_name'>"+App.utils.joueursInfos[player.id]+"</div></div>");
        });
    },

    renderDiaporama: function () {
        $(".block_diapo").slick({
            infinite:true,
            centerMode: true,
            centerPadding: '20px',
            slidesToShow: 1,
        });
    },

    handleScroll: function () {
        var self = this;

        var notAnimatedContentBlocks = $(".content_block.notanimated");
        var bounds = App.$AppContent[0].getBoundingClientRect();

        _.each(notAnimatedContentBlocks, function (el) {
            blockOffset = el.getBoundingClientRect();
            if(blockOffset.top < (App.wHeight * (2/3))){
                var $el = $(el);
                var dataBlock = $el.attr("data-block");
                App.utils.saveUserProgression(dataBlock);

                $el.removeClass("notanimated")
                    .addClass("animated");

                var nbOdometer = $el.find(".odometer").attr("data-nbodometer");
                $el.find(".odometer").html(nbOdometer);

                if(dataBlock == "dribbles") {
                    App.$silhouetteDribble.addClass("displayed");
                    App.$silhouetteDribbleJoueur.addClass("displayed");
                } else if(dataBlock == "arrets") {
                    App.$silhouetteKeeper.addClass("displayed");
                }
            }
        });

        var parallax1Offset = App.$Parallax1[0].getBoundingClientRect();
        if(parallax1Offset.top < App.wHeight) {
            var parallaxShift = (App.wHeight - parallax1Offset.top)*-1;
            App.$Parallax1[0].style.transform = "translate(-50%, "+parallaxShift*0.3+"px)";
        }

        var parallax2Offset = App.$Parallax2[0].getBoundingClientRect();
        if(parallax2Offset.top < App.wHeight) {
            var parallaxShift = (App.wHeight - parallax2Offset.top)*-1;
            App.$Parallax2[0].style.transform = "translate(-50%, "+parallaxShift*0.1+"px)";
        }        
    },

    bindEvents:function(){
    	var self = this;

        $(".splash_bt").on("click", function (){
            $("html, body").removeClass("locked")
                            .removeClass("locked_domed");
            localStorage.setItem('om-year-review', 'agreed');
        })

        $(".slide_startbt").on("click", function (){
            self.quizSlide = "question_1";
            self.loadQuizGifs();
            App.utils.saveUserProgression("defi_start");
            self.updateQuiz();
        });

        $(".answer_bt").on("click", function (){
            var $this = $(this);
            var parentQuizSlide = $this.parents(".quiz_slide");
            var dataQuestion = $(parentQuizSlide).attr("data-slide");
            var questionNB = dataQuestion.split("_")[1];
            App.utils.saveUserProgression("defi_q"+questionNB);

            var goodAnswer = $(parentQuizSlide).attr("data-goodanwser");
            var btAnswer = $this.attr("data-joueur");

            if(btAnswer == goodAnswer) {
                self.quizResults[self.quizSlide] = "win";
            } else {
                self.quizResults[self.quizSlide] = "lose";
            }

            self.quizQuestionStep = 2;
            self.updateQuiz("answer");      
        });

        $(".subscribe_bt").on("click", function (){
            App.utils.saveUserProgression("abo_click");
        })

        $(".step_nextbt").on("click", function (){
            var dataQuestion = $(this).attr("data-question");
            if(dataQuestion == "question_1") self.quizSlide = "question_2";
            else if(dataQuestion == "question_2") self.quizSlide = "question_3";

            self.quizQuestionStep = 1;

            if(dataQuestion == "question_3") {
                $([document.documentElement, document.body]).animate({
                    scrollTop: $(".content_block[data-block='12homme']").offset().top
                }, 2000);
            } else {
                self.updateQuiz();
            }
        });

        $(".social_link[data-social='tw']").on("click", function (){
            App.utils.saveUserProgression("tw_click");
        });

        $(".social_link[data-social='fb']").on("click", function (){
            App.utils.saveUserProgression("fb_click");
            FB.ui(
                { 
                    method: 'share_open_graph',
                    action_type: 'og.likes',
                    display:"popup",
                    action_properties: JSON.stringify({
                        object: {
                            'og:url': location.href ,
                            'og:description': 'lamalamalam',
                            'redirect_uri': location.href
                        }
                    })
                }, 
                function(response){ console.log(response); }
            );
        });

        $(".wdd_link").on("click", function (){
            App.utils.saveUserProgression("wdd_click");
        })
    },

    loadQuizGifs: function () {
        var arrayWinGifs = ['images/gif/win1-payet.gif', 'images/gif/win2-sakai.gif', 'images/gif/win3-thauvin.gif'];
        var winGif1 = chance.weighted(arrayWinGifs, [1,1,1]);
        $(".quiz_slide[data-slide='question_1'] .slide_step[data-step='2'] .step_toggle[data-toggle='win'] .block_gif img").attr("src", winGif1);
        arrayWinGifs = _.without(arrayWinGifs, winGif1);
        var winGif2 = chance.weighted(arrayWinGifs, [1,1]);
        $(".quiz_slide[data-slide='question_2'] .slide_step[data-step='2'] .step_toggle[data-toggle='win'] .block_gif img").attr("src", winGif2);
        arrayWinGifs = _.without(arrayWinGifs, winGif2);
        $(".quiz_slide[data-slide='question_3'] .slide_step[data-step='2'] .step_toggle[data-toggle='win'] .block_gif img").attr("src", arrayWinGifs[0]);

        var arrayLoseGifs = ['images/gif/lose1-amavi.gif', 'images/gif/lose2-rami.gif', 'images/gif/lose3-caletacar.gif'];
        var loseGif1 = chance.weighted(arrayLoseGifs, [1,1,1]);
        $(".quiz_slide[data-slide='question_1'] .slide_step[data-step='2'] .step_toggle[data-toggle='lose'] .block_gif img").attr("src", loseGif1);
        arrayLoseGifs = _.without(arrayLoseGifs, loseGif1);
        var loseGif2 = chance.weighted(arrayLoseGifs, [1,1]);
        $(".quiz_slide[data-slide='question_2'] .slide_step[data-step='2'] .step_toggle[data-toggle='lose'] .block_gif img").attr("src", loseGif2);
        arrayLoseGifs = _.without(arrayLoseGifs, loseGif2);
        $(".quiz_slide[data-slide='question_3'] .slide_step[data-step='2'] .step_toggle[data-toggle='lose'] .block_gif img").attr("src", arrayLoseGifs[0]);
    },

    updateQuiz: function (type) {
        var self = this;

        if(this.quizSlide == "question_1") {
            this.$quizWrapper.css("left", "-100%");
        } else if(this.quizSlide == "question_2") {
            this.$quizWrapper.css("left", "-200%");
        } else if(this.quizSlide == "question_3") {
            this.$quizWrapper.css("left", "-300%");
        } else if(this.quizSlide == "intro") {
            this.$quizWrapper.css("left", "0%");
        }

        $(".quiz_slide[data-slide='"+this.quizSlide+"']").attr("data-step", this.quizQuestionStep);

        if(type == "answer") {
            $(".quiz_slide[data-slide='"+this.quizSlide+"']").attr("data-result", this.quizResults[this.quizSlide]);
            setTimeout(function (){
                $(".quiz_slide[data-slide='"+self.quizSlide+"'] .slide_step[data-step='2']").addClass("aftergif");
            }, 3000);
        }
    },

    launchHashURLAnimation: function () {
        App.animateHashURL();
    },

    animateHashURL: function () {

        if(App.hashAnimationCount >= App.hashAnimationNb) {
            clearTimeout(App.hashAnimationTimeout)
            location.hash = App.userNoAbonne;
        } else {
            if(App.hashTimelineInc >= App.hashTimeline.length) {
                App.hashAnimationCount += 1;
                App.hashTimelineInc = 0;
            }

            App.router.updateRoute();

            App.hashAnimationTimeout = setTimeout(function(){
                App.hashTimelineInc += 1;
                App.animateHashURL()
            }, App.hashAnimationDuration);
        }
    }

};

module.exports = App;
window.App = App;