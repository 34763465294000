var AppRouter = Backbone.Router.extend({

    routes: {
        "":"routeFromURL",
        ":param1":"routeWithParameter",
        ":param1/":"routeWithParameter",
        ":param1/:param2":"routeWith2Parameters",
    },

    routeFromURL:function(params){
        location.href = "splash.php";
    },

    routeWithParameter:function(param1){
      App.userNoAbonne = param1;
      App.updateFromRouter();
    },

    routeWith2Parameters:function(param1, param2){
      App.userNoAbonne = param1;
      App.updateFromRouter();
    },

    
    updateRoute:function(triggerize){
        this.navigate( App.userNoAbonne, {trigger: triggerize});
    },

});

module.exports = AppRouter;