var Utils = Backbone.View.extend({

    initialize:function(){
        var self = this;

        this.abonnesNb = 32732;
        this.allAccess = {};
        /*this.allAverages = {
            passesTotalPct:0,
            passesAvgPct:0,
            taclesTotalPct:0,
            taclesAvgPct:0,
            duelsTotalPct:0,
            duelsAvgPct:0,
            dribblesTotalPct:0,
            dribblesAvgPct:0,
            tirsTotalPct:0,
            tirsAvgPct:0
        };*/
        this.allAverages = {"passesTotalPct":86393,"passesAvgPct":84.78213935230619,"taclesTotalPct":64607,"taclesAvgPct":63.402355250245336,"duelsTotalPct":54071,"duelsAvgPct":53.06280667320903,"dribblesTotalPct":46166,"dribblesAvgPct":45.30520117762512,"tirsTotalPct":36115,"tirsAvgPct":35.44160942100098};
        this.allJoueurs = [];
        this.allGames = [];

        this.maxUserData = {
            "arrets":22,
            "goals":29,
            "photoGoals":["amiens-balotelli","amiens-thauvin","angers-balotelli1","angers-balotelli2","caen-mitroglou","caen-thauvin","dijon-ocampos","dijon-rami","guingamp-mitroglou","guingamp-payet","guingamp-thauvin1","guingamp-thauvin2","lille-balotelli","monaco-lopez","nice-balotelli","rennes-bensebaini","rennes-ocampos","saint-etienne-balotelli","saint-etienne-thauvin","strasbourg-germain","strasbourg-payet","strasbourg-sanson","toulouse-germain","toulouse-payet1","toulouse-payet2","toulouse-thauvin"]
        };

        this.joueursInfos = {
            "balotelli": "Mario Balotelli",
            "thauvin": "Florian Thauvin",
            "payet": "Dimitri Payet",
            "germain": "Valère Germain",
            "mitroglou": "Kostas Mitroglou",
            "mandanda": "Steve Mandanda",
            "pele": "Yohann Pelé",
            "amavi": "Jordan Amavi",
            "rami": "Adil Rami",
            "strootman":"Kevin Strootman",
            "sarr":"Bouna Sarr",
            "sertic":"Grégory Sertic",
            "luiz-gustavo":"Luiz Gustavo",
            "lopez":"Maxime Lopez",
            "njie":"Clinton N'Jie",
            "ocampos":"Lucas Ocampos",
            "kamara":"Boubacar Kamara",
            "sakai":"Hiroki Sakai",
            "sanson":"Morgan Sanson"
        };

        this.mapUserTribune = {
            "Tribune De Peretti":"De Peretti",
            "Tribune Chevalier Roze":"Chevalier Roze",
            "Tribune Ganay":"Ganay",
            "Tribune Jean Bouin":"Jean Bouin"
        };

        this.joueurs = {};
        this.games = {};

        this.quizJoueurs = {
            "question_1":{
                "joueurs":[]
            },
            "question_2":{
                "joueurs":[]
            },
            "question_3":{
                "joueurs":[],
                "top5":[]
            }
        };
    },

    gatherDBData:function(callback){
        var self = this;

        $.getJSON("api/getData.php", {
            aboID:App.userNoAbonne
        }).done(function(data){
            console.log("gatherDBData", data);
            var userGames = [];

            _.each(data.acces, function (game){
                userGames.push(game.id_match);
            })

            App.userData = {
                games: userGames,
                wins: 0,
                goals: 0,
                dribbles: 0,
                dribblesTotal: 0,
                dribblesPct: 0,
                passes: 0,
                passesTotal: 0,
                passesPct: 0,
                tacles: 0,
                taclesTotal: 0,
                taclesPct: 0,
                tirs: 0,
                tirsTotal: 0,
                tirsPct: 0,
                duels: 0,
                duelsTotal: 0,
                duelsPct: 0,
                arrets: 0,
                cartons_jaunes: 0,
                cartons_rouges: 0,
                minutes: parseInt(data.abonne.duree, 10),
                ranking: parseInt(data.abonne.ranking, 10),
                photoGoals: [],
                aboData: data.abonne,
                accesdata: data.acces,
                cleansheets: 0,
                supporters: 0,
                zoneStade: "",
                VAR: 0,
                groupeSupp: data.abonne.groupe
            };

            self.computeUserZoneStade()
            return callback();
        })
    },

    computeUserZoneStade: function () {
        var tribune = App.userData.aboData.secteur; //Tribune De Peretti
        var block = App.userData.aboData.block;
        block = block.toLowerCase();

        if(tribune == "Tribune Chevalier Roze"){
            App.userData.zoneStade = "virage-sud";
        } else if(tribune == "Tribune De Peretti"){
            App.userData.zoneStade = "virage-nord";
        } else if(tribune == "Tribune Ganay"){
            App.userData.zoneStade = "ganay-" + block;
        } else if(tribune == "Tribune Jean Bouin"){
            App.userData.zoneStade = "jeanbouin-" + block;
        }

    },

    gatherCSVJoueurs: function (callback) {
        var self = this;
        d3.csv("data/stats_joueurs.csv", function(data){
            self.allJoueurs.push(data);

            var joueurID = _.str.slugify(data.joueur);
            if(self.joueurs[joueurID] == undefined) {
                self.joueurs[joueurID] = {
                    id: joueurID,
                    duels: 0,
                    dribbles: 0,
                    passes: 0,
                    arrets: 0,
                    minutes: 0,
                    buts: 0
                }
            }

            if(data.valeur != "" && _.indexOf(App.userData.games, data.match) > -1) {
                if(data.stats == "duels_reussis") {
                    self.joueurs[joueurID].duels += parseInt(data.valeur, 10);
                } else if(data.stats == "passes_reussies") {
                    self.joueurs[joueurID].passes += parseInt(data.valeur, 10);
                } else if(data.stats == "minutes") {
                    self.joueurs[joueurID].minutes += parseInt(data.valeur, 10);
                } else if(data.stats == "buts") {
                    self.joueurs[joueurID].buts += parseInt(data.valeur, 10);
                } else if(data.stats == "arrets_reussis") {
                    self.joueurs[joueurID].arrets += parseInt(data.valeur, 10);
                    App.userData.arrets += parseInt(data.valeur, 10);
                } else if(data.stats == "dribbles_reussi"){
                    self.joueurs[joueurID].dribbles += parseInt(data.valeur, 10);
                }
            }
        }, function(error, rows) {
            self.computeQuizJoueurs();
            return callback();
        });
    },

    computeQuizJoueurs: function (){
        var sortedJoueursByDuels = _.sortBy(this.joueurs, function(j){ return -j.duels;});
        var q1Joueurs = sortedJoueursByDuels.slice(0, 3);
        this.quizJoueurs["question_1"].goodAnswer = q1Joueurs[0].id;
        this.quizJoueurs["question_1"].goodAnswerNb = q1Joueurs[0].duels;
        this.quizJoueurs["question_1"].joueurs = _.shuffle(q1Joueurs);

        var sortedJoueursByPasses = _.sortBy(this.joueurs, function(j){ return -j.passes;});
        var q2Joueurs = sortedJoueursByPasses.slice(0, 3);
        this.quizJoueurs["question_2"].goodAnswer = q2Joueurs[0].id;
        this.quizJoueurs["question_2"].goodAnswerNb = q2Joueurs[0].passes;
        this.quizJoueurs["question_2"].joueurs = _.shuffle(q2Joueurs);

        var sortedJoueursByMinutes = _.sortBy(this.joueurs, function(j){ if(j.id == "mandanda" || j.id == "pele") return 0; return -j.minutes;});
        var q3Joueurs = sortedJoueursByMinutes.slice(0, 3);
        this.quizJoueurs["question_3"].goodAnswer = q3Joueurs[0].id;
        this.quizJoueurs["question_3"].goodAnswerNb = q2Joueurs[0].minutes;
        this.quizJoueurs["question_3"].joueurs = _.shuffle(q3Joueurs);
        this.quizJoueurs["question_3"].top5 = sortedJoueursByMinutes.slice(1, 5);
    },

    gatherCSVGames: function (callback) {
        var self = this;
        d3.csv("data/stats_om.csv", function(data){
            self.allGames.push(data);

            if(data.indicateur == "victoire"){
                _.each(App.userData.games, function (gameID){
                    if(data[gameID] !== undefined && data[gameID] == "victoire") {
                        App.userData.wins += 1;
                    }
                })
            }

            if(data.indicateur == "buts"){
                _.each(App.userData.games, function (gameID){
                    if(data[gameID] !== undefined) {
                        App.userData.goals += parseInt(data[gameID], 10);
                    }
                })
            }

            if(data.indicateur == "dribbles_reussis") {
                _.each(App.userData.games, function (gameID){
                    if(data[gameID] !== undefined) {
                        App.userData.dribbles += parseInt(data[gameID], 10);
                    }
                })
            }

            if(data.indicateur == "dribbles") {
                _.each(App.userData.games, function (gameID){
                    if(data[gameID] !== undefined) {
                        App.userData.dribblesTotal += parseInt(data[gameID], 10);
                    }
                })
            }

            if(data.indicateur == "passes_reussies") {
                _.each(App.userData.games, function (gameID){
                    if(data[gameID] !== undefined) {
                        App.userData.passes += parseInt(data[gameID], 10);
                    }
                })
            }

            if(data.indicateur == "passes") {
                _.each(App.userData.games, function (gameID){
                    if(data[gameID] !== undefined) {
                        App.userData.passesTotal += parseInt(data[gameID], 10);
                    }
                })
            }

            if(data.indicateur == "tacles_reussis") {
                _.each(App.userData.games, function (gameID){
                    if(data[gameID] !== undefined) {
                        App.userData.tacles += parseInt(data[gameID], 10);
                    }
                })
            }

            if(data.indicateur == "tacles") {
                _.each(App.userData.games, function (gameID){
                    if(data[gameID] !== undefined) {
                        App.userData.taclesTotal += parseInt(data[gameID], 10);
                    }
                })
            }

            if(data.indicateur == "duels_gagnes") {
                _.each(App.userData.games, function (gameID){
                    if(data[gameID] !== undefined) {
                        App.userData.duels += parseInt(data[gameID], 10);
                    }
                })
            }

            if(data.indicateur == "duels") {
                _.each(App.userData.games, function (gameID){
                    if(data[gameID] !== undefined) {
                        App.userData.duelsTotal += parseInt(data[gameID], 10);
                    }
                })
            }

            if(data.indicateur == "tirs_cadres") {
                _.each(App.userData.games, function (gameID){
                    if(data[gameID] !== undefined) {
                        App.userData.tirs += parseInt(data[gameID], 10);
                    }
                })
            }

            if(data.indicateur == "tirs_total") {
                _.each(App.userData.games, function (gameID){
                    if(data[gameID] !== undefined) {
                        App.userData.tirsTotal += parseInt(data[gameID], 10);
                    }
                })
            }

            if(data.indicateur == "cartons_jaunes") {
                _.each(App.userData.games, function (gameID){
                    if(data[gameID] !== undefined) {
                        App.userData.cartons_jaunes += parseInt(data[gameID], 10);
                    }
                })
            }

            if(data.indicateur == "cartons_rouges") {
                _.each(App.userData.games, function (gameID){
                    if(data[gameID] !== undefined) {
                        App.userData.cartons_rouges += parseInt(data[gameID], 10);
                    }
                })
            }

            if(data.indicateur == "clean_sheet") {
                _.each(App.userData.games, function (gameID){
                    if(data[gameID] !== undefined) {
                        if(data[gameID] == "1") {
                            App.userData.cleansheets += 1;
                        }
                    }
                })
            }

            if(data.indicateur == "affluence") {
                _.each(App.userData.games, function (gameID){
                    if(data[gameID] !== undefined) {
                        App.userData.supporters += parseInt(data[gameID], 10);
                    }
                })
            }

            if(data.indicateur == "VAR") {
                _.each(App.userData.games, function (gameID){
                    if(data[gameID] !== undefined) {
                        App.userData.VAR += parseInt(data[gameID], 10);
                    }
                })
            }

        }, function(error, rows) {
            self.computeUserBadge()
            return callback();
        });
    },

    computeUserBadge: function () {
        App.userData.passesPct = (App.userData.passes / App.userData.passesTotal) * 100;
        App.userData.dribblesPct = (App.userData.dribbles / App.userData.dribblesTotal) * 100;
        App.userData.tirsPct = (App.userData.tirs / App.userData.tirsTotal) * 100;
        App.userData.duelsPct = (App.userData.duels / App.userData.duelsTotal) * 100;
        App.userData.taclesPct = (App.userData.tacles / App.userData.taclesTotal) * 100;

        var badgePctEcartType = {
            passes:{
                value:((App.userData.passesPct - this.allAverages.passesAvgPct) /this.allAverages.passesAvgPct)*100,
                badgePct: App.userData.passesPct,
                id: "maestro",
                attrType: "passes<br />réussies",
                badgeText: "Quand tu étais au stade,<br />jamais les Olympiens n’ont réussi<br />autant de passes."
            },
            dribbles:{
                value:((App.userData.dribblesPct - this.allAverages.dribblesAvgPct) /this.allAverages.dribblesAvgPct)*100,
                badgePct: App.userData.dribblesPct,
                id:"artiste",
                attrType: "dribbles<br />réussis",
                badgeText: "Quand tu étais au stade,<br />jamais les Olympiens n’ont réussi<br />autant de dribbles."
            },
            tirs:{
                value:((App.userData.tirsPct - this.allAverages.tirsAvgPct) /this.allAverages.tirsAvgPct)*100,
                badgePct: App.userData.tirsPct,
                id: "sniper",
                attrType: "tirs<br />cadrés",
                badgeText: "Quand tu étais au stade,<br />jamais les Olympiens n’ont cadré<br />autant de tirs."
            },
            duels:{
                value:((App.userData.duelsPct - this.allAverages.duelsAvgPct)/this.allAverages.duelsAvgPct)*100,
                badgePct: App.userData.duelsPct,
                id:"roc",
                attrType: "duels<br />gagnés",
                badgeText: "Quand tu étais au stade,<br />jamais les Olympiens n’ont gagné<br />autant de duels."
            },
            tacles:{
                value:((App.userData.taclesPct - this.allAverages.taclesAvgPct)/this.allAverages.taclesAvgPct)*100,
                badgePct: App.userData.taclesPct,
                id:"guerrier",
                attrType:"tacles<br />réussis",
                badgeText: "Quand tu étais au stade,<br />jamais les Olympiens n’ont réussi<br />autant de tacles."
            }
        };

        console.log("badgePctEcartType", badgePctEcartType);
        var maxEcart = _.max(badgePctEcartType, function(et){ return et.value; });
        App.userData.badge = maxEcart;
    },

    gatherAllAccess: function () {
        var self = this;

        $.getJSON("api/getAllAccess.php").done(function(data){
            console.log(data);
            self.allAccess = data;
            self.computeAllAccess();
        })
    },

    computeAllAccess: function () {
        var self = this;
        var groupedAbonnes = _.groupBy(this.allAccess, function(access){
            return access.id_abonne;
        })

        this.allAbonnes = {};

        _.each(groupedAbonnes, function(aboObj){
            var gamesIDS = _.map(aboObj, function(gameData){ return gameData.id_match });
            var idAbonne = aboObj[0].id_abonne;

            self.allAbonnes[idAbonne] = {
                id:idAbonne,
                passesTotal:0,
                passesReussies:0,
                passesPct:0,
                taclesTotal:0,
                taclesReussis:0,
                taclesPct:0,
                duelsTotal:0,
                duelsReussis:0,
                duelsPct:0,
                dribblesTotal:0,
                dribblesReussis:0,
                dribblesPct:0,
                tirsTotal:0,
                tirsCadres:0,
                tirsPct:0
            };

            _.each(self.allGames, function(indicateurData){
                _.each(gamesIDS, function (gameID){
                    if(indicateurData[gameID] !== undefined) {
                        if(indicateurData.indicateur == "dribbles"){
                        self.allAbonnes[idAbonne].dribblesTotal += parseInt(indicateurData[gameID], 10);
                        } else if(indicateurData.indicateur == "dribbles_reussis"){
                            self.allAbonnes[idAbonne].dribblesReussis += parseInt(indicateurData[gameID], 10);
                        } else if(indicateurData.indicateur == "passes"){
                            self.allAbonnes[idAbonne].passesTotal += parseInt(indicateurData[gameID], 10);
                        } else if(indicateurData.indicateur == "passes_reussies"){
                            self.allAbonnes[idAbonne].passesReussies += parseInt(indicateurData[gameID], 10);
                        } else if(indicateurData.indicateur == "tacles"){
                            self.allAbonnes[idAbonne].taclesTotal += parseInt(indicateurData[gameID], 10);
                        } else if(indicateurData.indicateur == "tacles_reussis"){
                            self.allAbonnes[idAbonne].taclesReussis += parseInt(indicateurData[gameID], 10);
                        } else if(indicateurData.indicateur == "tirs_total"){
                            self.allAbonnes[idAbonne].tirsTotal += parseInt(indicateurData[gameID], 10);
                        } else if(indicateurData.indicateur == "tirs_cadres"){
                            self.allAbonnes[idAbonne].tirsCadres += parseInt(indicateurData[gameID], 10);
                        } else if(indicateurData.indicateur == "duels"){
                            self.allAbonnes[idAbonne].duelsTotal += parseInt(indicateurData[gameID], 10);
                        } else if(indicateurData.indicateur == "duels_gagnes"){
                            self.allAbonnes[idAbonne].duelsReussis += parseInt(indicateurData[gameID], 10);
                        }
                    }
                })
            })

            self.allAbonnes[idAbonne].passesPct = Math.round((self.allAbonnes[idAbonne].passesReussies/self.allAbonnes[idAbonne].passesTotal)*100);
            self.allAbonnes[idAbonne].taclesPct = Math.round((self.allAbonnes[idAbonne].taclesReussis/self.allAbonnes[idAbonne].taclesTotal)*100);
            self.allAbonnes[idAbonne].duelsPct = Math.round((self.allAbonnes[idAbonne].duelsReussis/self.allAbonnes[idAbonne].duelsTotal)*100)
            self.allAbonnes[idAbonne].dribblesPct = Math.round((self.allAbonnes[idAbonne].dribblesReussis/self.allAbonnes[idAbonne].dribblesTotal)*100)
            self.allAbonnes[idAbonne].tirsPct = Math.round((self.allAbonnes[idAbonne].tirsCadres/self.allAbonnes[idAbonne].tirsTotal)*100)

            var badgePctEcartType = {
                passes:{
                    value:((self.allAbonnes[idAbonne].passesPct - self.allAverages.passesAvgPct) /self.allAverages.passesAvgPct)*100,
                    badgePct: App.userData.passesPct,
                    id: "maestro"
                },
                dribbles:{
                    value:((self.allAbonnes[idAbonne].dribblesPct - self.allAverages.dribblesAvgPct) /self.allAverages.dribblesAvgPct)*100,
                    badgePct: App.userData.dribblesPct,
                    id:"artiste"
                },
                tirs:{
                    value:((self.allAbonnes[idAbonne].tirsPct - self.allAverages.tirsAvgPct) /self.allAverages.tirsAvgPct)*100,
                    badgePct: App.userData.tirsPct,
                    id: "sniper"
                },
                duels:{
                    value:((self.allAbonnes[idAbonne].duelsPct - self.allAverages.duelsAvgPct)/self.allAverages.duelsAvgPct)*100,
                    badgePct: App.userData.duelsPct,
                    id:"roc"
                },
                tacles:{
                    value:((self.allAbonnes[idAbonne].taclesPct - self.allAverages.taclesAvgPct)/self.allAverages.taclesAvgPct)*100,
                    badgePct: App.userData.taclesPct,
                    id:"guerrier"
                }
            };

            var maxEcart = _.max(badgePctEcartType, function(et){ return et.value; });
            self.allAbonnes[idAbonne].badge = maxEcart;
        });

        delete this.allAbonnes[""];
        var aboLength = 0;
        _.each(this.allAbonnes, function(abo){
            if(!isNaN(abo.passesPct)){
                self.allAverages.passesTotalPct += abo.passesPct;
                aboLength += 1;
            }
            if(!isNaN(abo.dribblesPct)) self.allAverages.dribblesTotalPct += abo.dribblesPct;
            if(!isNaN(abo.duelsPct)) self.allAverages.duelsTotalPct += abo.duelsPct;
            if(!isNaN(abo.taclesPct)) self.allAverages.taclesTotalPct += abo.taclesPct;
            if(!isNaN(abo.tirsPct)) self.allAverages.tirsTotalPct += abo.tirsPct;
        });

        self.allAverages.passesAvgPct = self.allAverages.passesTotalPct / aboLength;
        self.allAverages.dribblesAvgPct = self.allAverages.dribblesTotalPct / aboLength;
        self.allAverages.duelsAvgPct = self.allAverages.duelsTotalPct / aboLength;
        self.allAverages.taclesAvgPct = self.allAverages.taclesTotalPct / aboLength;
        self.allAverages.tirsAvgPct = self.allAverages.tirsTotalPct / aboLength;
    },

    saveUserProgression: function(progressID) {
        var savedID = "";
        if(progressID == "intro") {
            savedID = "intro";
        } else if(progressID == "matchs") {
            savedID = "chiffres";
        } else if(progressID == "buts_diapo") {
            savedID = "diapo";
        } else if(progressID == "badge") {
            savedID = "badge";
        } else if(progressID == "quiz") {
            savedID = "defi";
        } else if(progressID == "defi_start") {
            savedID = "defi_start";
        } else if(progressID == "defi_q1") {
            savedID = "defi_q1";
        } else if(progressID == "defi_q2") {
            savedID = "defi_q2";
        } else if(progressID == "defi_q3") {
            savedID = "defi_q3";
        } else if(progressID == "12homme") {
            savedID = "adversaires";
        } else if(progressID == "statultime"){
            savedID = "statultime";
        } else if(progressID == "abo_click"){
            savedID = "abo_click";
        } else if(progressID == "tw_click"){
            savedID = "tw_click";
        } else if(progressID == "fb_click"){
            savedID = "fb_click";
        } else if(progressID == "wdd_click"){
            savedID = "wdd_click";
        }

        if(savedID !== "") {
            $.post("api/saveUserProgression.php", {
                randomID: App.randomID,
                progressID: savedID
            }).done(function(data){
                console.log("end save user progression");
            })
        }
    }
});

module.exports = Utils;